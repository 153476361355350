.timeline-div {
  min-height: 100vh;
  padding: 120px 0 80px 0;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 49px 0px; */
}
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: var(--color-secondary);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: var(--color-secondary);
  border: 4px solid var(--color-primary);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
.container:hover:after {
  background-color: var(--color-primary);
}
/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid var(--color-secondary);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--color-secondary);
}
.left:hover::before {
  border-color: transparent transparent transparent transparent;
}
/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid var(--color-secondary);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--color-secondary) transparent transparent;
}
.right:hover::before {
  border-color: transparent transparent transparent transparent;
}
/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: var(--color-secondary);
  position: relative;
  border-radius: 6px;
  transition: .3s ease-in;
}
.content:hover {
  background-color: var(--color-primary);
  color: #fff;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
  left: 28px;
  }
  
  /* Full-width containers */
  .container {
  width: 100%;
  padding-left: 70px;
  padding-right: 25px;
  }
  
  /* Make sure that all arrows are pointing leftwards */
  .container::before {
  left: 60px;
  border: medium solid var(--color-secondary);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--color-secondary) transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
  left: 15px;
  }
  
  /* Make all right containers behave like the left ones */
  .right {
  left: 0%;
  }
}



@media screen and (max-width: 1000px) {
  /* Place the timelime to the left */
  .timeline-div {
  padding: 80px 0px;
  margin-bottom: 100px;
  }
}