a {
  text-decoration: none;
}
.nav-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 60px;
  color: var(--color-tertiary);
  padding: 0 8em;
  z-index: 999;
  position: fixed;
  width: 100vw;
  background-color: rgb(255, 255, 255);
}
.nav-logo{
  text-align: left;
  line-height: 20px;
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 800;
  transform: translateY(-5%);
}

.nav-logo img {
  width: 30px;
  padding-top: 10px;
}
.burger-menu{
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 75%);
  font-size: 1.8rem;
  cursor: pointer;
}
.nav-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 15px;
  width: 70vw;
  list-style: none;
  justify-content: end;
}
.nav-item {
  padding: 6px 0;
  border-bottom: 3px solid transparent;

}
.nav-item:hover {
  color: var(--color-primary);
  transition: all .2s ease-in;
}
.nav-item a {
  padding: 14px 1.5em;
  font-size: 16px;
  color: var(--color-tertiary);
}
.nav-item a:hover{
  color: var(--color-primary);
}

@media (max-width: 1000px) {
  .nav-div{
  /* background-color: var(--color-primary); */
  color: var(--color-tertiary);
  position: relative;
  height: 80px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
.nav-logo img {
  padding-top: 0px;
}
  .burger-menu {
    position: absolute;
    right: 0;
    top:0;
    display: block;
    text-decoration: none;
    color: var(--color-tertiary);
    font-size: 40px;
    transform: translate(-40%, 38%);
    }

  .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* font-size: 25px; */
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left: -150%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-list.active {
    padding: 0;
    /* padding-top: 30px;
    margin-top: 40px; */
    background-color: #ffffff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;font-weight: bold;
    }
  .nav-item {
    text-align: center;
    width: 100%;
    display: table;
  }
  .nav-item:hover {
    background-color: var(--color-secondary);
    border-color: transparent;
  }
  .nav-item a {
    text-align: center;
    padding: 1.5rem 0;
    width: 100%;
    display: table;
    margin: 0;
    font-size: 28px;
  }
  .nav-logo{
    position: absolute;
    left: 0;
    top:0;
    color: var(--color-tertiary);
    font-size: 18px;
    font-weight: 900;
    transform: translate(30%, 15%);
  }
}