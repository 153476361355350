.project-card {
  aspect-ratio: 1/1;
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .2s ease-in;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: .2s ease-in;
  background-color: #fff;
  /* min-height: 300px; */
}
.project-card:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.project-img {
  min-height: 50%;
  width: 100%;
  /* background-color: var(--color-tertiary); */
}

.project-img-exp {
  min-height: 50%;
  width: 100%;
  background-color: var(--color-tertiary);
}

.project-img img {
  min-height: 50%;
  width: 100%;
  background-color: var(--color-secondary);
  transition: .5s ease;
}

.project-img img:hover {
  filter: grayscale(100%);
}
.project-detail {
  min-height: 80%;
  width: 100%;
  /* background-color: violet; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.p-title {
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}
.p-detail {
  font-size: 14px;
  font-weight: 300;
  margin: 0 10px;
  text-align: justify;
}
.p-button {
  margin: 10px;
  display: flex;
}

.p-btn {
  width: 100px;
  height: 28px;
  margin: 5px 10px 10px;
  border: none;
  cursor: pointer;
  transition: .2s ease-in;
}

.p-btn-primary {
  background-color: var(--color-primary);
  color: #fff;
}
.p-btn-primary:hover {
  background-color: var(--color-tertiary);
  color: #fff;
}
.p-btn-secondary {
  background-color: var(--color-secondary);
   /* transition: border 0.3s ease, color 0.3s ease; */
}
.p-btn-secondary:hover {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}



@media (max-width: 1000px) {
.project-card {
  margin: 20px;
}
}

