.skill-card {
  aspect-ratio: 1/1;
  overflow: auto;
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .2s ease-in;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;

}
.skill-card:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.skill-card img {
  width: 3em;
}

.skill-card span {
  margin-top: 10px;
}

@media (max-width:900px) {
  .skill-card span {
  font-size: 13px;
  font-weight: 600;
}

}