
:root {
  --color-primary: #522258;
  --color-secondary: #FFEDFA;
  --color-tertiary: #141414;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
.App {
  text-align: center;
  margin: 0;
}

html {
  background-color: #ffffff;
  color: var(--color-tertiary);
  overflow-y: scroll;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
body {
  flex-grow: 1;
}
.page-title {
  margin: 0;
  padding-bottom: 50px;
}

.layout-div {
  /* height: 100vh; */
  background-color: blanchedalmond;
}
code {
  /* background-color: black; */
  color: rgb(113, 113, 113);
  padding: 8px 20px;
  text-align: start;
}

.blog-note-div {
  background-color: #DEF5E5;
  padding: 8px 20px;
}
.blog-a-tag {
  color: var(--color-primary);
}
.blog-single-code {
  background-color: aliceblue;
  padding: 8px 20px;
  justify-content: left;
  text-align: left;
}
.blog-multi-code {
  background-color: black;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
}

@media (max-width: 1000px) {
  code, pre {
    font-size: 12px;
  }

  code {
    /* background-color: black; */
    color: rgb(113, 113, 113);
    padding: 8px 0px;
    text-align: start;
  }
}